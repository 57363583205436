export default {
    zh: {
        hint: '点击，或拖动图片至此处',
        loading: '正在上传……',
        noSupported: '浏览器不支持该功能，请使用IE10以上或其他现在浏览器！',
        success: '上传成功',
        fail: '图片上传失败',
        preview: '头像预览',
        btn: {
            off: '取消',
            close: '关闭',
            back: '上一步',
            save: '保存'
        },
        error: {
            onlyImg: '仅限图片格式',
            outOfSize: '单文件大小不能超过 ',
            lowestPx: '图片最低像素为（宽*高）：'
        }
    },
    'zh-tw': {
        hint: '點擊，或拖動圖片至此處',
        loading: '正在上傳……',
        noSupported: '瀏覽器不支持該功能，請使用IE10以上或其他現代瀏覽器！',
        success: '上傳成功',
        fail: '圖片上傳失敗',
        preview: '頭像預覽',
        btn: {
            off: '取消',
            close: '關閉',
            back: '上一步',
            save: '保存'
        },
        error: {
            onlyImg: '僅限圖片格式',
            outOfSize: '單文件大小不能超過 ',
            lowestPx: '圖片最低像素為（寬*高）：'
        }
    },
    en: {
        hint: 'Click or drag the file here to upload',
        loading: 'Uploading…',
        noSupported: 'Browser is not supported, please use IE10+ or other browsers',
        success: 'Upload success',
        fail: 'Upload failed',
        preview: 'Preview',
        btn: {
            off: 'Cancel',
            close: 'Close',
            back: 'Back',
            save: 'Save'
        },
        error: {
            onlyImg: 'Image only',
            outOfSize: 'Image exceeds size limit: ',
            lowestPx: 'Image\'s size is too low. Expected at least: '
        }
    },
    ro: {
        hint: 'Atinge sau trage fișierul aici',
        loading: 'Se încarcă',
        noSupported: 'Browser-ul tău nu suportă acest feature. Te rugăm încearcă cu alt browser.',
        success: 'S-a încărcat cu succes',
        fail: 'A apărut o problemă la încărcare',
        preview: 'Previzualizează',

        btn: {
            off: 'Anulează',
            close: 'Închide',
            back: 'Înapoi',
            save: 'Salvează'
        },

        error: {
            onlyImg: 'Doar imagini',
            outOfSize: 'Imaginea depășește limita de: ',
            loewstPx: 'Imaginea este prea mică; Minim: '
        }
    },
    ru: {
        hint: 'Нажмите, или перетащите файл в это окно',
        loading: 'Загружаю……',
        noSupported: 'Ваш браузер не поддерживается, пожалуйста, используйте IE10 + или другие браузеры',
        success: 'Загрузка выполнена успешно',
        fail: 'Ошибка загрузки',
        preview: 'Предпросмотр',
        btn: {
            off: 'Отменить',
            close: 'Закрыть',
            back: 'Назад',
            save: 'Сохранить'
        },
        error: {
            onlyImg: 'Только изображения',
            outOfSize: 'Изображение превышает предельный размер: ',
            lowestPx: 'Минимальный размер изображения: '
        }
    },
    'pt-br': {
        hint: 'Clique ou arraste o arquivo aqui para carregar',
        loading: 'Carregando...',
        noSupported: 'Browser não suportado, por favor utilize o Internet Explorer 10+ ou outro browser',
        success: 'Imagem carregada com sucesso',
        fail: 'Ocorreu um erro ao carregar a imagem',
        preview: 'Pré-visualização',
        btn: {
            off: 'Cancelar',
            close: 'Fechar',
            back: 'Voltar',
            save: 'Salvar'
        },
        error: {
            onlyImg: 'Por favor envie apenas imagens',
            outOfSize: 'A imagem excede o limite de tamanho suportado: ',
            lowestPx: 'O tamanho da imagem é muito pequeno. Tamanho mínimo: '
        }
    },
    'pt-pt': {
        hint: 'Clique ou arraste o arquivo para a janela para carregar',
        loading: 'A processar...',
        noSupported: 'Browser não suportado, por favor utilize o Internet Explorer 10+ ou outro browser',
        success: 'Imagem carregada com sucesso',
        fail: 'Ocorreu um erro ao carregar a imagem',
        preview: 'Pré-visualização',
        btn: {
            off: 'Cancelar',
            close: 'Fechar',
            back: 'Voltar',
            save: 'Guardar'
        },
        error: {
            onlyImg: 'Por favor envie apenas imagens',
            outOfSize: 'A imagem excede o limite de tamanho suportado: ',
            lowestPx: 'O tamanho da imagem é muito pequeno. Tamanho mínimo: '
        }
    },
    fr: {
        hint: 'Cliquez ou glissez le fichier ici.',
        loading: 'Téléchargement…',
        noSupported: 'Votre navigateur n\'est pas supporté. Utilisez IE10 + ou un autre navigateur s\'il vous plaît.',
        success: 'Téléchargement réussi',
        fail: 'Téléchargement echoué',
        preview: 'Aperçu',
        btn: {
            off: 'Annuler',
            close: 'Fermer',
            back: 'Retour',
            save: 'Enregistrer'
        },
        error: {
            onlyImg: 'Image uniquement',
            outOfSize: 'L\'image sélectionnée dépasse la taille maximum: ',
            lowestPx: 'L\'image sélectionnée est trop petite. Dimensions attendues: '
        }
    },
    nl: {
        hint: 'Klik hier of sleep een afbeelding in dit vlak',
        loading: 'Uploaden…',
        noSupported: 'Je browser wordt helaas niet ondersteund. Gebruik IE10+ of een andere browser.',
        success: 'Upload succesvol',
        fail: 'Upload mislukt',
        preview: 'Voorbeeld',
        btn: {
            off: 'Annuleren',
            close: 'Sluiten',
            back: 'Terug',
            save: 'Opslaan'
        },
        error: {
            onlyImg: 'Alleen afbeeldingen',
            outOfSize: 'De afbeelding is groter dan: ',
            lowestPx: 'De afbeelding is te klein! Minimale afmetingen: '
        }
    },
    tr: {
        hint: 'Tıkla veya yüklemek istediğini buraya sürükle',
        loading: 'Yükleniyor…',
        noSupported: 'Tarayıcı desteklenmiyor, lütfen IE10+ veya farklı tarayıcı kullanın',
        success: 'Yükleme başarılı',
        fail: 'Yüklemede hata oluştu',
        preview: 'Önizle',
        btn: {
            off: 'İptal',
            close: 'Kapat',
            back: 'Geri',
            save: 'Kaydet'
        },
        error: {
            onlyImg: 'Sadece resim',
            outOfSize: 'Resim yükleme limitini aşıyor: ',
            lowestPx: 'Resmin boyutu çok küçük. En az olması gereken: '
        }
    },
    'es-MX': {
        hint: 'Selecciona o arrastra una imagen',
        loading: 'Subiendo...',
        noSupported: 'Tu navegador no es soportado, por favor usa IE10+ u otros navegadores más recientes',
        success: 'Subido exitosamente',
        fail: 'Sucedió un error',
        preview: 'Vista previa',
        btn: {
            off: 'Cancelar',
            close: 'Cerrar',
            back: 'Atrás',
            save: 'Guardar'
        },
        error: {
            onlyImg: 'Únicamente imágenes',
            outOfSize: 'La imagen excede el tamaño maximo:',
            lowestPx: 'La imagen es demasiado pequeña. Se espera por lo menos:'
        }
    },
    de: {
        hint: 'Klick hier oder zieh eine Datei hier rein zum Hochladen',
        loading: 'Hochladen…',
        noSupported: 'Browser wird nicht unterstützt, bitte verwende IE10+ oder andere Browser',
        success: 'Upload erfolgreich',
        fail: 'Upload fehlgeschlagen',
        preview: 'Vorschau',
        btn: {
            off: 'Abbrechen',
            close: 'Schließen',
            back: 'Zurück',
            save: 'Speichern'
        },
        error: {
            onlyImg: 'Nur Bilder',
            outOfSize: 'Das Bild ist zu groß: ',
            lowestPx: 'Das Bild ist zu klein. Mindestens: '
        }
    },
    ja: {
        hint: 'クリック・ドラッグしてファイルをアップロード',
        loading: 'アップロード中...',
        noSupported: 'このブラウザは対応されていません。IE10+かその他の主要ブラウザをお使いください。',
        success: 'アップロード成功',
        fail: 'アップロード失敗',
        preview: 'プレビュー',
        btn: {
            off: 'キャンセル',
            close: '閉じる',
            back: '戻る',
            save: '保存'
        },
        error: {
            onlyImg: '画像のみ',
            outOfSize: '画像サイズが上限を超えています。上限: ',
            lowestPx: '画像が小さすぎます。最小サイズ: '
        }
    },
    ua: {
        hint: 'Натисніть, або перетягніть файл в це вікно',
        loading: 'Завантажую……',
        noSupported: 'Ваш браузер не підтримується, будь ласка скористайтесь IE10 + або іншими браузерами',
        success: 'Завантаження виконано успішно',
        fail: 'Помилка завантаження',
        preview: 'Попередній перегляд',
        btn: {
            off: 'Відмінити',
            close: 'Закрити',
            back: 'Назад',
            save: 'Зберегти'
        },
        error: {
            onlyImg: 'Тільки зображення',
            outOfSize: 'Зображення перевищує граничний розмір: ',
            lowestPx: 'Мінімальний розмір зображення: '
        }
    },
    it: {
        hint: 'Clicca o trascina qui il file per caricarlo',
        loading: 'Caricamento del file…',
        noSupported: 'Browser non supportato, per favore usa IE10+ o un altro browser',
        success: 'Caricamento completato',
        fail: 'Caricamento fallito',
        preview: 'Anteprima',
        btn: {
            off: 'Annulla',
            close: 'Chiudi',
            back: 'Indietro',
            save: 'Salva'
        },
        error: {
            onlyImg: 'Sono accettate solo immagini',
            outOfSize: 'L\'immagine eccede i limiti di dimensione: ',
            lowestPx: 'L\'immagine è troppo piccola. Il requisito minimo è: '
        }
    },
    ar: {
        hint: 'اضغط أو اسحب الملف هنا للتحميل',
        loading: 'جاري التحميل...',
        noSupported: 'المتصفح غير مدعوم ، يرجى استخدام IE10 + أو متصفح أخر',
        success: 'تم التحميل بنجاح',
        fail: 'فشل التحميل',
        preview: 'معاينه',
        btn: {
            off: 'إلغاء',
            close: 'إغلاق',
            back: 'رجوع',
            save: 'حفظ'
        },
        error: {
            onlyImg: 'صور فقط',
            outOfSize: 'تتجاوز الصوره الحجم المحدد: ',
            lowestPx: 'حجم الصورة صغير جدا. من المتوقع على الأقل: '
        }
    },
    ug: {
        hint: 'مەزكۇر دائىرىنى چىكىپ رەسىم تاللاڭ ياكى رەسىمنى سۆرەپ ئەكىرىڭ',
        loading: 'يوللىنىۋاتىدۇ...',
        noSupported: 'تور كۆرگۈچ بۇ ئىقتىدارنى قوللىمايدۇ ، يۇقىرى نەشىردىكى تور كۆرگۈچنى ئىشلىتىڭ',
        success: 'غەلبىلىك بولدى',
        fail: 'مەغلۇب بولدى',
        preview: 'ئۈنۈم رەسىم',
        btn: {
            off: 'بولدى قىلىش',
            close: 'تاقاش',
            back: 'ئالدىنقى قەدەم',
            save: 'ساقلاش'
        },
        error: {
            onlyImg: 'پەقەت رەسىم فورماتىنىلا قوللايدۇ',
            outOfSize: 'رەسىم چوڭ - كىچىكلىكى چەكتىن ئىشىپ كەتتى',
            lowestPx: 'رەسىمنىڭ ئەڭ كىچىك ئۆلچىمى :'
        }
    },
    th: {
        hint: 'คลิ๊กหรือลากรูปมาที่นี่',
        loading: 'กำลังอัพโหลด…',
        noSupported: 'เบราเซอร์ไม่รองรับ, กรุณาใช้ IE เวอร์ชั่น 10 ขึ้นไป หรือใช้เบราเซอร์ตัวอื่น',
        success: 'อัพโหลดสำเร็จ',
        fail: 'อัพโหลดล้มเหลว',
        preview: 'ตัวอย่าง',
        btn: {
            off: 'ยกเลิก',
            close: 'ปิด',
            back: 'กลับ',
            save: 'บันทึก'
        },
        error: {
            onlyImg: 'ไฟล์ภาพเท่านั้น',
            outOfSize: 'ไฟล์ใหญ่เกินกำหนด: ',
            lowestPx: 'ไฟล์เล็กเกินไป. อย่างน้อยต้องมีขนาด: '
        }
    },
    mm: {
        hint: 'ဖိုင်ကို ဤနေရာတွင် နှိပ်၍ (သို့) ဆွဲထည့်၍ တင်ပါ',
        loading: 'တင်နေသည်…',
        noSupported: 'ဤဘရောက်ဇာကို အထောက်အပံ့ မပေးပါ၊ ကျေးဇူးပြု၍ IE10+ သို့မဟုတ် အခြား ဘရောက်ဇာ ကို အသုံးပြုပါ',
        success: 'ဖိုင်တင်နေမှု မပြီးမြောက်ပါ',
        fail: 'ဖိုင်တင်နေမှု မအောင်မြင်ပါ',
        preview: 'အစမ်းကြည့်',
        btn: {
            off: 'မလုပ်တော့ပါ',
            close: 'ပိတ်မည်',
            back: 'နောက်သို့',
            save: 'သိမ်းမည်'
        },
        error: {
            onlyImg: 'ဓာတ်ပုံ သီးသန့်သာ',
            outOfSize: 'ဓာတ်ပုံဆိုဒ် ကြီးလွန်းသည် ။ အများဆုံး ဆိုဒ် : ',
            lowestPx: 'ဓာတ်ပုံဆိုဒ် သေးလွန်းသည်။ အနည်းဆုံး ဆိုဒ် : '
        }
    },
    se: {
        hint: 'Klicka eller dra en fil hit för att ladda upp den',
        loading: 'Laddar upp…',
        noSupported: 'Din webbläsare stöds inte, vänligen använd IE10+ eller andra webbläsare',
        success: 'Uppladdning lyckades',
        fail: 'Uppladdning misslyckades',
        preview: 'Förhandsgranska',
        btn: {
            off: 'Avbryt',
            close: 'Stäng',
            back: 'Tillbaka',
            save: 'Spara'
        },
        error: {
            onlyImg: 'Endast bilder',
            outOfSize: 'Bilden är större än max-gränsen: ',
            lowestPx: 'Bilden är för liten. Minimum är: '
        }
    },
    pl: {
        hint: 'Kliknij lub upuść plik tutaj',
        loading: 'Wgrywanie…',
        noSupported: 'Twoja przeglądarka nie jest wspierana, użyj IE10+ lub innej przeglądarki',
        success: 'Błąd',
        fail: 'Sukces',
        preview: 'Podgląd',
        btn: {
            off: 'Anuluj',
            close: 'Zamknij',
            back: 'Wstecz',
            save: 'Zapisz'
        },
        error: {
            onlyImg: 'Tylko obrazki',
            outOfSize: 'Rozmiar obrazka przekracza: ',
            lowestPx: 'Obrazek jest za mały. Minimalne wymiary to: '
        }
    },
    vi: {
        hint: 'Bấm hoặc kéo thả file để tải lên',
        loading: 'Đang tải lên...',
        noSupported: 'Trình duyệt không hỗ trợ, hãy sử dụng IE10+ hoặc trình duyệt khác',
        success: 'Tải lên thành công',
        fail: 'Tải lên thất bại',
        preview: 'Xem trước',
        btn: {
            off: 'Huỷ',
            close: 'Đóng',
            back: 'Trở lại',
            save: 'Lưu'
        },
        error: {
            onlyImg: 'Chỉ hình ảnh',
            outOfSize: 'Hình ảnh vượt quá giới hạn cho phép: ',
            lowestPx: 'Kích thước hình quá nhỏ. Kích thước tối thiểu: '
        }
    },
    fa: {
        hint: 'برای بارگذاری تصویر کلیک کنید یا تصویر را به این ناحیه درگ کنید',
        loading: 'در حال بارگذاری ...',
        noSupported: 'نسخه یا نوع مرورگر شما از این قابلیت پشتیبانی نمیکند. لطفا از اینترنت اکسپلورر ورژن بالاتر از ده یا مرورگرهای دیگر استفاده کنید',
        success: 'بارگذاری با موفقیت انجام شد',
        fail: 'بارگذاری انجام نشد',
        preview: 'پیشنمایش',
        btn: {
            off: 'لغو',
            close: 'بستن',
            back: 'بازگشت',
            save: 'ذخیره'
        },
        error: {
            onlyImg: 'فقط تصویر',
            outOfSize: 'حجم تصویر بیش از اندازه‌ی مجاز است: ',
            lowestPx: 'حجم تصویر بسیار پایین است، حداقل سایز تصویر: '
        }
    },
    da: {
        hint: 'Klik eller træk en fil herhen for at uploade',
        loading: 'Uploader…',
        noSupported: 'Din browser er ikke understøttet, benyt venligst IE10+ eller en anden browser',
        success: 'Upload lykkedes',
        fail: 'Upload mislykkedes',
        preview: 'Preview',
        btn: {
            off: 'Fortryd',
            close: 'Luk',
            back: 'Tilbage',
            save: 'Gem'
        },
        error: {
            onlyImg: 'Kun billeder',
            outOfSize: 'Billedet overskrider størrelsesgrænsen: ',
            lowestPx: 'Billedet er for lille. Minimumsstørrelsen er: '
        }
    },
    ko: {
        hint: '클릭 또는 드래그하여 이미지를 업로드하세요.',
        loading: '업로드 중…',
        noSupported: '죄송합니다, 인터넷 익스플로러 버전 10 이상 혹은 다른 브라우저를 사용해주세요.',
        success: '업로드 성공',
        fail: '업로드 실패',
        preview: '미리보기',
        btn: {
            off: '취소',
            close: '닫기',
            back: '뒤로가기',
            save: '저장'
        },
        error: {
            onlyImg: '이미지만 업로드 할 수 있습니다.',
            outOfSize: '업로드 할 수 있는 최대 이미지 사이즈를 초과했습니다: ',
            lowestPx: '이미지의 크기는 적어도 다음 사이즈보다 커야 합니다: '
        }
    },
};
