<template>
    <div id="registration-step-two" :class="{'edit-page': editData}">
        <h1 class="page-title desktop" v-if="!editData">
            <span>Шаг 2 из 2. </span>
            <span>А эту информацию можно заполнить позже</span>
        </h1>
        <div class="page-title mobile" v-if="!editData">
            <div class="title">Регистрация</div>
            <div class="visualized-steps">
                <span class="active done"></span>
                <span class="active">2</span>
            </div>
            <div class="descr">А эту информацию можно заполнить позже</div>
        </div>
        <div class="privacy-confirmation" v-if="!editData">
            <img src="../../../../assets/img/privacy-no-one.svg" alt="privacy">
            <p>Мы никогда не опубликуем ничего без вашего ведома, и вы всегда сможете скрыть любые данные</p>
        </div>
        <div class="c-wrapper">
            <div class="form-wrapper">
                <ValidationObserver v-slot="{invalid, handleSubmit}" ref="form">
                    <form class="u-col-12" v-on:submit.prevent="handleSubmit(onSubmit($event))">
                        <div class="form-group-wrapper">
                            <div class="basic-user-data">
                                <div class="orientation_id-title">
                                    Ориентация
                                </div>
                                <div class="radio-buttons-wrapper">
                                    <div class="form-group u-radiobutton-wr no-icon" v-for="i of registrationData.orientations">
                                        <ValidationProvider :rules="(validationDisabled.orientation_id) ? '' : 'required'" v-slot="{classes, errors}">
	                                        <!--@click="uncheckItem(i, 'orientation_id')"-->
                                            <input :id="i.name + i.id" type="radio" :value="i" @click="uncheckItem(i, 'orientation_id')" v-model="orientation_id" :name="i.name + i.id"/>
                                            <label class="label-as-input" :for="i.name + i.id" v-bind:class="classes">
                                                <span class="checkmark"></span>
                                                {{i.name}}
                                            </label>
                                        </ValidationProvider>
                                    </div>
                                </div>
	                            <div class="u-checkbox-wr left-side">
		                            <label for="deselect-orientation_id" class="checkbox-container">
			                            <input id="deselect-orientation_id" type="checkbox" class='custom-checkbox' v-model="validationDisabled.orientation_id" @change="triggerAttentionModal('orientation_id')">
			                            <span class="checkmark"></span>
			                            Не указываю
		                            </label>
	                            </div>
                            </div>
                            <div class="privacy-settings">
                                <div class="form-group">
                                    <label for="orientation_id-privacy" class="regular-label">
                                        Кто увидит
                                    </label>
                                    <privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="orientation_visibility" field="orientation_visibility"></privacy-dropdown-reg>
                                </div>
                            </div>
                        </div>
                        <div class="form-group-grid">
                            <div class="form-group-wrapper">
                                <div class="grid-group-header">
                                    <div class="grid-group-title">
                                        Семейное положение
                                    </div>
                                    <div class="privacy-settings no-label">
                                        <div class="form-group">
                                            <label for="marital-status-privacy" class="regular-label">
                                                Кто увидит
                                            </label>
                                            <privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="family_status_visibility" field="family_status_visibility"></privacy-dropdown-reg>
                                        </div>
                                    </div>
                                </div>
                                <div class="basic-user-data">
                                    <div class="form-group u-radiobutton-wr" v-for="i of registrationData.family_statuses">
                                        <ValidationProvider :rules="(validationDisabled.family_status_id) ? '' : 'required'" v-slot="{classes, errors}">
                                            <input type="radio" v-bind:id="i.name + i.id" @click="uncheckItem(family_status_id, 'family_status_id')" :value="i" v-model="family_status_id">
                                            <label v-bind:for="i.name + i.id" v-bind:class="classes" class="label-as-input border-none"><span class="checkmark"></span> {{i.name}}</label>
                                        </ValidationProvider>
                                    </div>
                                </div>
	                            <div class="u-checkbox-wr left-side">
		                            <label for="deselect-family_status_id" class="checkbox-container">
			                            <input id="deselect-family_status_id" type="checkbox" class='custom-checkbox' v-model="validationDisabled.family_status_id" @change="triggerAttentionModal('family_status_id')">
			                            <span class="checkmark"></span>
			                            Не указываю
		                            </label>
	                            </div>
                            </div>
                            <div class="form-group-wrapper">
                                <div class="grid-group-header">
                                    <div class="grid-group-title">
                                        Дети
                                    </div>
                                    <div class="privacy-settings no-label">
                                        <div class="form-group">
                                            <label for="children_id-privacy" class="regular-label">
                                                Кто увидит
                                            </label>
                                            <privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="children_visibility" field="children_visibility"></privacy-dropdown-reg>
                                        </div>
                                    </div>
                                </div>
                                <div class="basic-user-data">
                                    <div class="form-group u-radiobutton-wr" v-for="i of registrationData.children">
                                        <ValidationProvider :rules="(validationDisabled.children_id) ? '' : 'required'" v-slot="{classes, errors}">
                                            <input type="radio" v-bind:id="i.name + i.id" @click="uncheckItem(children_id, 'children_id')" v-bind:value="i" v-model="children_id">
                                            <label v-bind:for="i.name + i.id" v-bind:class="classes" class="label-as-input border-none"><span class="checkmark"></span> {{i.name}}</label>
                                        </ValidationProvider>
                                    </div>
                                </div>
	                            <div class="u-checkbox-wr left-side">
		                            <label for="deselect-children_id" class="checkbox-container">
			                            <input id="deselect-children_id" type="checkbox" class='custom-checkbox' v-model="validationDisabled.children_id" @change="triggerAttentionModal('children_id')">
			                            <span class="checkmark"></span>
			                            Не указываю
		                            </label>
	                            </div>
                            </div>
                            <div class="form-group-wrapper">
                                <div class="grid-group-header">
                                    <div class="grid-group-title">
                                        Образование
                                    </div>
                                    <div class="privacy-settings no-label">
                                        <div class="form-group">
                                            <label for="education_id-privacy" class="regular-label">
                                                Кто увидит
                                            </label>
                                            <privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="education_visibility" field="education_visibility"></privacy-dropdown-reg>
                                        </div>
                                    </div>
                                </div>
                                <div class="basic-user-data">
                                    <div class="form-group u-radiobutton-wr" v-for="i of registrationData.educations">
                                        <ValidationProvider :rules="(validationDisabled.education_id) ? '' : 'required'" v-slot="{classes, errors}">
                                            <input type="radio" v-bind:id="i.name + i.id" @click="uncheckItem(education_id, 'education_id')" v-bind:value="i" v-model="education_id">
                                            <label v-bind:for="i.name + i.id" v-bind:class="classes" class="label-as-input border-none"><span class="checkmark"></span> {{i.name}}</label>
                                        </ValidationProvider>
                                    </div>
                                </div>
	                            <div class="u-checkbox-wr left-side">
		                            <label for="deselect-education_id" class="checkbox-container">
			                            <input id="deselect-education_id" type="checkbox" class='custom-checkbox' v-model="validationDisabled.education_id" @change="triggerAttentionModal('education_id')">
			                            <span class="checkmark"></span>
			                            Не указываю
		                            </label>
	                            </div>
                            </div>
                            <div class="form-group-wrapper">
                                <div class="grid-group-header">
                                    <div class="grid-group-title">
                                        работа и учеба
                                    </div>
                                    <div class="privacy-settings no-label">
                                        <div class="form-group">
                                            <label for="work_status_id-privacy" class="regular-label">
                                                Кто увидит
                                            </label>
                                            <privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="work_status_visibility" field="work_status_visibility"></privacy-dropdown-reg>
                                        </div>
                                    </div>
                                </div>
                                <div class="basic-user-data">
                                    <div class="form-group u-radiobutton-wr" v-for="i of registrationData.work_statuses">
                                        <ValidationProvider :rules="(validationDisabled.work_status_id) ? '' : 'required'" v-slot="{classes, errors}">
                                            <input type="radio" v-bind:id="i.name + i.id" @click="uncheckItem(work_status_id, 'work_status_id')" v-bind:value="i" v-model="work_status_id">
                                            <label v-bind:for="i.name + i.id" v-bind:class="classes" class="label-as-input border-none"><span class="checkmark"></span> {{i.name}}</label>
                                        </ValidationProvider>
                                    </div>
                                </div>
	                            <div class="u-checkbox-wr left-side">
		                            <label for="deselect-work_status_id" class="checkbox-container">
			                            <input id="deselect-work_status_id" type="checkbox" class='custom-checkbox' v-model="validationDisabled.work_status_id" @change="triggerAttentionModal('work_status_id')">
			                            <span class="checkmark"></span>
			                            Не указываю
		                            </label>
	                            </div>
                            </div>
                        </div>
                        <div class="form-group-wrapper">
                            <div class="basic-user-data">
                                <div class="form-group no-margin">
                                    <label for="occupation" class="regular-label">
                                        род занятий
                                    </label>
                                    <multiselect :validation="(validationDisabled.occupation) ? '' : 'required'" id="occupation" placeholder="Выберите из списка" :list="registrationData.occupations" :listSelected="occupationsSelected" @itemSelected="uncheckItem(occupation, 'occupation')"></multiselect>
                                </div>
                            </div>
                            <div class="privacy-settings">
                                <div class="form-group">
                                    <label for="occupation-privacy" class="regular-label">
                                        Кто увидит
                                    </label>
                                    <privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="occupation_visibility" field="occupation_visibility"></privacy-dropdown-reg>
                                </div>
                            </div>
	                        <div class="u-checkbox-wr left-side">
		                        <label for="deselect-occupation" class="checkbox-container">
			                        <input id="deselect-occupation" type="checkbox" class='custom-checkbox' v-model="validationDisabled.occupation" @change="triggerAttentionModal('occupation')">
			                        <span class="checkmark"></span>
			                        Не указываю
		                        </label>
	                        </div>
                        </div>
                        <div class="form-group-wrapper">
                            <div class="basic-user-data">
                                <div class="form-group no-margin">
                                    <label for="interests" class="regular-label">
                                        интересы
                                    </label>
                                    <multiselect ref="multiselect" :validation="(validationDisabled.interests) ? '' : 'required'" id="interests" placeholder="Выберите из списка" :list="registrationData.interests" :listSelected="interestsSelected" @itemSelected="uncheckItem(interests, 'interests')"></multiselect>
                                </div>
                            </div>
                            <div class="privacy-settings">
                                <div class="form-group">
                                    <label for="interests-privacy" class="regular-label">
                                        Кто увидит
                                    </label>
                                    <privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="interest_visibility" field="interest_visibility"></privacy-dropdown-reg>
                                </div>
                            </div>
	                        <div class="u-checkbox-wr left-side">
		                        <label for="deselect-interests" class="checkbox-container">
			                        <input id="deselect-interests" type="checkbox" class='custom-checkbox' v-model="validationDisabled.interests" @change="triggerAttentionModal('interests')">
			                        <span class="checkmark"></span>
			                        Не указываю
		                        </label>
	                        </div>
                        </div>
                    </form>
                </ValidationObserver>
                <div class="controls-wrapper">
	                <c_button v-if="!editData" :class="{'loading': loadingIndicator}" :c_loading="loadingIndicator" :c_class="'blank-button postpone-btn'" :c_text="'пропустить и заполнить позже'" :c_loading_reverse="false" @clickEvent="handleSubmit(true)" :disabled="loadingIndicator"></c_button>
	
	                <c_button :c_loading="loadingIndicator" :c_class="'submit-btn def-btn'" :c_text="'Продолжить'" :c_loading_reverse="true" @clickEvent="handleSubmit(false)" :disabled="loadingIndicator"></c_button>
	                
                    <!--<button class="blank-button postpone-btn" @click="handleSubmit(true)" :disabled="loadingIndicator" v-if="!editData">пропустить и заполнить позже</button>-->
                    <!--<button class="def-btn submit-btn" :class="{'loading-spinner-active': loadingIndicator, 'left': editData}" :disabled="loadingIndicator" @click="handleSubmit(false)">
                        <span>Продолжить</span>
                        <img src="../../../../assets/img/Infinity-1s-200px.svg" alt="loading">
                    </button>-->
                </div>
            </div>
        </div>
	    
	    <custom-modal ref="custom-modal"></custom-modal>
    </div>
</template>

<script>
    import multiselect from "../../../../components/_shared/Multiselect";
    import privacyDropdownReg from "../../../../components/_shared/PrivacyDropdownReg";
    import {authService} from "../../../../_api";
    import {handleAWSImage} from "../../../../_helpers/aws-handler";
    import {CHILDREN_OPTIONS, FAMILY_STATUS_OPTIONS, ORIENTATIONS_OPTIONS, EDUCATION_OPTIONS, WORK_AND_STUDY_OPTIONS} from "../../../../_models/static";
    import C_button from "@/components/_shared/Button";
	import Vue from "vue";
    import CustomModal from "@/components/_shared/modals/_CustomModal";

    export default {
        name: 'registration-step-two',
        props: ['registrationData', 'editData'],
        components: {
	        CustomModal,
	        C_button,
            'multiselect': multiselect,
            'privacy-dropdown-reg': privacyDropdownReg
        },
        data() {
            return {
                loadingIndicator: false,
                orientation_options: ORIENTATIONS_OPTIONS,
                children_options: CHILDREN_OPTIONS,
                marital_status_options: FAMILY_STATUS_OPTIONS,
                work_and_study_options: WORK_AND_STUDY_OPTIONS,
                education_options: EDUCATION_OPTIONS,
                occupationsSelected: [],
                interestsSelected: [],
	            orientation_id: '',
	            orientation_visibility: 1,
	            family_status_id: '',
	            family_status_visibility: 1,
	            children_id: '',
	            children_visibility: 1,
	            education_id: '',
	            education_visibility: 1,
	            work_status_id: '',
	            work_status_visibility: 1,
	            occupation: '',
	            occupation_visibility: 1,
	            interests: '',
	            interest_visibility: 1,
	            validationDisabled: {
		            orientation_id: false,
		            family_status_id: false,
		            children_id: false,
		            education_id: false,
		            work_status_id: false,
		            occupation: false,
		            interests: false,
	            },
                avatar: null
            }
        },
        created () {
            if (!this.editData) {
                this.getUserAvatar();
                let regData = JSON.parse(localStorage.getItem('uReg'));
                if (regData && regData.sOne) {

                } else {
                    this.$router.push('/registration/step-one');
                }
            } else {
                this.userData = {...this.editData};
				console.log(this.editData);
                this['birthday_parsed'] = this.moment(this.editData.birthday, 'YYYY-MM-DD').format('YYYY-MM-DD');
                this['name_visibility'] = 1;
                this['birthday_show_range'] = false;
                this['orientation_id'] = this.editData.orientation;
                this['orientation_visibility'] = this.editData.orientation_visibility;
                this['family_status_id'] = this.editData.family_status;
                this['family_status_visibility'] = this.editData.family_status_visibility;
                this['children_id'] = this.editData.children;
                this['children_visibility'] = this.editData.children_visibility;
                this['education_id'] = this.editData.education;
                this['education_visibility'] = this.editData.education_visibility;
                this['work_status_id'] = this.editData.work_status;
                this['work_status_visibility'] = this.editData.work_status_visibility;
                this['occupations_id'] = this.editData.occupations;
                this['occupation_visibility'] = this.editData.occupation_visibility;
                this['interest_visibility'] = this.editData.interest_visibility;
                this['interests'] = this.editData.interests;
	            this.occupationsSelected = this.editData.occupations.map((obj) => {
		            obj['selected'] = true;
		            return obj
	            });
                for (let i = 0; i < this.occupationsSelected.length; i++) {
                    for (let ii = 0; ii < this.registrationData.occupations.length; ii++) {
                        if (this.occupationsSelected[i].id === this.registrationData.occupations[ii].id) {
                            this.registrationData.occupations[ii]['selected'] = true;
                        }
                    }
                }
                this.interestsSelected = this.editData.interests.map((obj) => {
                    obj['selected'] = true;
                    return obj
                });
                for (let i = 0; i < this.interestsSelected.length; i++) {
                    for (let ii = 0; ii < this.registrationData.interests.length; ii++) {
                        if (this.interestsSelected[i].id === this.registrationData.interests[ii].id) {
                            this.registrationData.interests[ii]['selected'] = true;
                        }
                    }
                }
				console.log(this.userData);
            }
        },
        computed: {},
        methods: {
			triggerAttentionModal(data) {
				if (this.validationDisabled[data]) {
					this.$refs['custom-modal'].show({
						title: 'Тогда вас не найдут по этому параметру',
						message: 'Тогда вас не найдут по этому параметру',
						okButton: 'OK',
						cancelButton: 'Отменить'
					}).then((result) => {
						if (result) {
							this.validationDisabled[data] = true;
							this.uncheckItem(this[data], data, true);
						} else {
							this.validationDisabled[data] = false;
						}
						this.$forceUpdate();
					})
				} else {
					this.validationDisabled[data] = false;
				}
			},
            uncheckItem(data, field, from_uncheck) {
				console.log(data);
				console.log(field);
	            if (field === 'occupation' || field === 'interests') {
		            console.log(this.occupationsSelected);
		            console.log(this.interestsSelected);
					if (field === 'occupation') {
						console.log('occupation')
						if (this.validationDisabled[field] === true) {
							console.log('asdasdasd');
							this.occupationsSelected = [];
							console.log(this.occupationsSelected);
							this.registrationData.occupations.forEach((obj) => {
								obj.selected = false;
								this.$forceUpdate();
							})
						}
					} else if (field === 'interests') {
						if (this.interestsSelected.length && this.validationDisabled[field] === true) {
							this.interestsSelected = [];
							this.registrationData.interests.forEach((obj) => {
								obj.selected = false;
								this.$forceUpdate();
							})
						}
					}
	            } else {
					if (from_uncheck) {
						this[field] = '';
					} else {
						this.validationDisabled[field] = false;
						if (this[field] && (this[field].id === data.id)) {
							this[field] = '';
						}
					}
	            }
	            this.$forceUpdate();
            },
            getUserAvatar() {
                return new Promise(((resolve, reject) => {
                    var objectStore = window.db.transaction("avatar").objectStore("avatar");
                    objectStore.openCursor().onsuccess = (event) => {
                        var cursor = event.target.result;
                        if (cursor) {
                            if (cursor.value.avatar) {
                                console.log(cursor);
                                this.avatar = cursor.value.avatar;
                            }
                            cursor.continue();
                        }
                        else {
                            console.log("No more entries!");
                            resolve();
                        }
                    };
                }))
            },
            updatePrivacy(data) {
                this[data.field] = data.id;
            },

            handleSubmit(postpone) {
                if (this.editData) {
                    this.$refs.form.validate().then(success => {
                        if (!success) {
                            return
                        } else {
                            let objToSend = {
								age_range: !!this.userData.age_range,
								place_id: this.userData.location ? this.userData.location.place_id : null,
                                orientation_id: (this.orientation_id) ? this.orientation_id.id : null,
                                orientation_visibility: this.orientation_visibility,
                                family_status_visibility: this.family_status_visibility,
                                education_visibility: this.education_visibility,
                                work_status_visibility: this.work_status_visibility,
                                occupation_visibility: this.occupation_visibility,
                                interest_visibility: this.interest_visibility,
                                family_status_id: this.family_status_id ? this.family_status_id.id : null,
                                children_id: this.children_id ? this.children_id.id : null,
                                children_visibility: this.children_visibility,
                                education_id: this.education_id ? this.education_id.id : null,
                                work_status_id: this.work_status_id ? this.work_status_id.id : null,
                                occupations_id: this.occupationsSelected.map((obj) => {return obj.id}),
                                interests_id: this.interestsSelected.map((obj) => {return obj.id}),
                            };
                            this.loadingIndicator = true;
                            console.log(objToSend);
	                        /*setTimeout(() => {
		                        this.loadingIndicator = false;
	                        }, 5000)*/
                            authService.updateUser(objToSend, null, false).then((res) => {
                                console.log(res);
                                if (res) {
                                    this.$emit('userDataUpdated');
                                    this.$store.dispatch('alert/success', 'Вы успешно изменили данные', { root: true });
                                }
                            });
                        }
                    })
                } else {
                    if (postpone) {
                        // this.loadingIndicator = true;
                        let tempData = JSON.parse(localStorage.getItem('uReg'));
                        let objToSend = {...tempData['sOne']};
                        objToSend['goals_id'] = tempData['sOne'].goals.map((obj) => {return obj.id});
                        objToSend['avatar'] = (this.avatar) ? this.avatar.split(',')[1] : null;
                        // objToSend['avatar'] = tempData['sOne'].avatar.split(',')[1];
                        objToSend['place_id'] = tempData['sOne'].location.place_id;
                        console.log(objToSend);

                        if (tempData['sOne'].loginSocial) {
                            objToSend['id'] = tempData['sOne'].id;
                            this.updateUserPost(objToSend, objToSend.api_key);
                        } else {
                            this.registerUserPost(objToSend);
                        }
                    }
                    else {
                        this.$refs.form.validate().then(success => {
                            if (!success) {
                                return;
                            } else {
	                            // this.$refs.form.reset();
	                            let asd = {
		                            orientation_id: this.orientation_id,
		                            orientation_visibility: this.orientation_visibility,
		                            family_status_id: this.family_status_id,
		                            family_status_visibility: this.family_status_visibility,
		                            children_id: this.children_id,
		                            children_visibility: this.children_visibility,
		                            education_id: this.education_id,
		                            education_visibility: this.education_visibility,
		                            work_status_id: this.work_status_id,
		                            work_status_visibility: this.work_status_visibility,
		                            occupation: this.occupation,
		                            occupation_visibility: this.occupation_visibility,
		                            interests: this.interests,
		                            interest_visibility: this.interest_visibility,
	                            }
                                let tempData = JSON.parse(localStorage.getItem('uReg'));
                                tempData['sTwo'] = asd;
                                localStorage.setItem('uReg', JSON.stringify(tempData));
                                let objToSend = {...tempData['sOne'], ...tempData['sTwo']};
                                objToSend['place_id'] = tempData['sOne'].location.place_id;
                                objToSend['avatar'] = (this.avatar) ? this.avatar.split(',')[1] : null;
                                objToSend['goals_id'] = tempData['sOne'].goals.map((obj) => {return obj.id});
                                objToSend['orientation_id'] = tempData['sTwo'].orientation_id.id;
                                objToSend['family_status_id'] = tempData['sTwo'].family_status_id.id;
                                objToSend['children_id'] = tempData['sTwo'].children_id.id;
                                objToSend['education_id'] = tempData['sTwo'].education_id.id;
                                objToSend['work_status_id'] = tempData['sTwo'].work_status_id.id;
                                objToSend['occupations_id'] = this.occupationsSelected.map((obj) => {return obj.id});
                                objToSend['interests_id'] = this.interestsSelected.map((obj) => {return obj.id});
                                console.log(objToSend);
                                if (tempData['sOne'].loginSocial) {
                                    objToSend['id'] = tempData['sOne'].id;
                                    let api_key = tempData['sOne'].api_key;
                                    this.updateUserPost(objToSend, api_key);
                                } else {
                                    this.registerUserPost(objToSend);
                                }
                            }
                        })
                    }
                }
            },
            registerUserPost(data) {
                this.loadingIndicator = true;
	            /*setTimeout(() => {
		            this.loadingIndicator = false;
	            }, 5000)*/
                authService.registerUser(data).then((res) => {
                    if (res) {
                        this.$store.dispatch('alert/success', 'Вы успешно зарегистрировали юзера', { root: true });
                        this.$router.push('/registration/email-confirmation');
                    }
                    this.loadingIndicator = false;
                });
            },
            updateUserPost(data, api_key) {
                this.loadingIndicator = true;
	
	            /*setTimeout(() => {
		            this.loadingIndicator = false;
	            }, 5000)*/
				
                authService.updateUser(data, api_key, true).then((res) => {
                    if (res) {
                        this.$store.dispatch('alert/success', 'Вы успешно зарегистрировали юзера', { root: true });
                        this.$store.dispatch('authentication/login', res.data);
                    }
                    this.loadingIndicator = false;
                });
            },
        },
        mounted() {}
    }
</script>

<style lang="less">
    @import "../../../../assets/styles/_vars";
    #registration-step-two {
        &.edit-page {
            .c-wrapper {
                .form-wrapper {
                    width: 100%!important;
                    form {
                        padding: 48px 57px 20px 57px;
                    }
                }
            }
            .controls-wrapper {
                padding: 0 10px 10px 10px;
            }
        }
        .page-title {
            &.mobile {
                display: none;
                margin-bottom: 16px!important;
                padding: 0!important;
                .title {
                    color: #9b9b9b;
                    font-size: 18px;
                    text-align: center;
                    margin-bottom: 3px;
                }
                .visualized-steps {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: nowrap;
                    margin-bottom: 20px;
                    span {
                        border-radius: 100%;
                        border: 3px solid #dfe3eb;
                        width: 34px;
                        height: 34px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 16px;
                        background-color: #cbcbcb;
                        color: #e0e3ea!important;
                        position: relative;
                        &.active {
                            background-color: #6d819f;
                            color: #ffffff!important;
                        }
                        &.done {
                            &:before {
                                content: "";
                                position: absolute;
                                left: 10px;
                                top: 6px;
                                width: 7px;
                                height: 13px;
                                border: solid #ffffff;
                                border-width: 0 2px 2px 0;
                                transform: rotate(45deg);
                            }
                        }
                        &:first-child {
                            margin-right: 20px;
                            &:after {
                                content: '';
                                width: 20px;
                                height: 3px;
                                background-color: #dfe3eb;
                                position: absolute;
                                top: 50%;
                                margin-top: -3px;
                                right: -23px;
                            }
                        }
                    }
                }
                .descr {
                    color: #666666;
                    font-size: 15px;
                    text-align: center;
                }
            }
        }
        .privacy-confirmation {
            display: none;
            background: #F8F8F8;
            border-radius: 5px;
            padding: 10px 15px 10px 15px;
            margin-bottom: 5px;
            img {
                width: 23px;
                margin-bottom: 5px;
            }
            p {
                color: #71809C;
                font-size: 11px;
                font-weight: 400;
                line-height: 15px;
                text-align: center;
                margin: 0;
            }
        }
        .c-wrapper {
            .form-wrapper {
                .form-group-wrapper {
                    .basic-user-data {
                        width: 75%;
                        .orientation_id-title {
                            font-size: 11px;
                            font-weight: 500;
                            text-transform: uppercase;
                            color: @text_color;
                            text-align: left;
                            display: flex;
                            margin-bottom: .5rem;
                        }
	                    .form-group {
		                    &.no-margin {
			                    margin: 0;
		                    }
	                    }
                        .radio-buttons-wrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: nowrap;
                            .form-group {
                                margin: 0;
                                width: 100%;
                                border-radius: 3px;
                                position: relative;
                                left: -1px;
                                margin-right: 5px;
                                margin-bottom: 5px;
                                span {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                }
                            }
                        }
                    }
                }
                .form-group-grid {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    .form-group-wrapper {
                        width: 45%;
                        .grid-group-header {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;
                            flex-wrap: nowrap;
                            width: 100%;
                            margin-bottom: 10px;
                            .grid-group-title {
                                font-size: 11px;
                                font-weight: 500;
                                text-transform: uppercase;
                                color: #666666;
                                text-align: left;
                                display: flex;
                                margin-bottom: 0;

                            }
                        }
                        .basic-user-data {
                            width: 100%;
                            background-color: #ffffff;
                            border: 1px solid rgba(94, 111, 143, 0.3);
                            border-radius: 3px;
                            padding: 35px 27px;
                            .form-group {
                                &:last-child {
                                    margin: 0;
                                }
                                span {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                }
                            }
                        }
                    }
                }
	            .controls-wrapper {
		            .submit-btn {
			            font-size: 16px;
			            font-weight: 500;
			            padding: 5px 10px;
			            min-width: 230px;
			            min-height: 50px;
		            }
	            }
            }
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #registration-step-two {
            .privacy-confirmation {
                display: block;
            }
            .page-title {
                &.desktop {
                    display: none;
                }
                &.mobile {
                    display: block;
                }
            }
            .c-wrapper {
                flex-wrap: wrap;
                .form-wrapper, .socials-auth-wrapper {
                    width: 100%!important;
                }
                .form-wrapper {
                    form {
                        padding: 20px 12px!important;
                        box-shadow: none;
                        .form-group-wrapper {
                            position: relative;
                            .basic-user-data {
                                width: 100%;
                                .radio-buttons-wrapper {
                                    flex-wrap: wrap;
                                    justify-content: space-between;
                                    .u-radiobutton-wr {
                                        margin-right: 0;
                                        &:not(:last-child) {
                                            width: 49%;
                                        }
                                        &:last-child {
                                            width: 100%;
                                        }
                                        label {
                                            padding: 5px 10px 5px 10px;
                                        }
                                    }
                                }
                                .form-group {
                                    margin-bottom: 5px;
                                    .dateofbirdth_option {
                                        label {
                                            text-align: left;
                                        }
                                    }
                                    #autocomplete-directive {
                                        .autogeoresult-block {
                                            font-size: 11px;
                                        }
                                    }
                                    #multiselect-directive {
                                        flex-wrap: wrap-reverse;
                                        justify-content: flex-start;
                                        align-items: flex-start;
                                        display: flex;
                                        .input-wrapper {
                                            width: 100%;
                                            margin-bottom: 10px;
                                            input {
                                                padding: 10px 15px;
                                                font-size: 14px;
                                            }
                                        }
                                        .selected-list {
                                            .selected-item {
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                            .privacy-settings {
                                position: absolute;
                                right: 0;
                                top: -6px;
                                width: 130px;
                                .form-group {
                                    .regular-label {
                                        display: none;
                                    }
                                    #privacy-dropdown-reg {
                                        .selected-item {
                                            padding: 0 10px;
                                            .icon {
                                                background-size: 20px;
                                                width: 20px;
                                                height: 20px;
                                            }
                                            .description {
                                                font-size: 12px;
                                            }
                                        }
                                        .dropdown-wrapper {
                                            .item {
                                                padding: 5px 10px;
                                                .icon {
                                                    background-size: 20px;
                                                    width: 20px;
                                                    height: 20px;
                                                }
                                                .description {
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.avatar-checkboxes {
                                margin-bottom: 0;
                                &>div {
                                    width: 100%!important;
                                    &:first-child {
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }
                        .form-group-grid {
                            .form-group-wrapper {
                                width: 48%;
                                .basic-user-data {
                                    padding: 10px;
                                }
                            }
                        }
                    }
                    .controls-wrapper {
                        justify-content: center;
                        .postpone-btn {
                            text-align: center;
                            margin: 5px;
                            font-size: 15px;
                            color: #666666;
                            border: 1px solid @theme_color;
                            //border-radius: 35px;
                            padding: 10px 10px;
                            width: 100%;
                            max-width: 320px;
	                        min-width: 320px;
                        }
                        .submit-btn {
                            margin: 5px;
                            font-size: 15px;
                            padding: 10px 30px;
                            width: 100%;
                            max-width: 320px;
                        }
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #registration-step-two {
            .c-wrapper {
                .form-wrapper {
                    form {
                        .form-group-grid {
                            .form-group-wrapper {
                                width: 100%!important;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
