<template>
    <div id="privacy-dropdown-reg" :class="{'disabled': isDisabled}">
        <div class="selected-item" @click="toggleDropdown">
            <span class="icon" v-bind:class="selectedItem.class"></span>
            <span class="description">{{selectedItem.description}}</span>
        </div>
        <div class="dropdown-wrapper" v-if="showDropdown">
            <div class="item" v-for="i in configureListOfOptions" @click="selectItem(i)">
                <span class="icon" v-bind:class="i.class"></span>
                <span class="description">{{i.description}}</span>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'privacy-dropdown-reg',
        props: {
            defaultSelected: {
                type: Number,
                required: false,
                default: 1
            },
	        hasNooneOption: {
				type: Boolean,
		        required: false,
		        default: false
	        },
            isDisabled: Boolean,
            id: {
                type: Number
            },
            field: {
                type: String
            }
        },
        data() {
            return {
                listOfItems: [
                    {
                        type: 0,
                        description: 'Никто',
                        class: 'secret'
                    },
                    {
                        type: 1,
                        description: 'Все',
                        class: 'eye'
                    },
                    {
                        type: 2,
                        description: 'Друзья Up&Up',
                        class: 'circle'
                    }
                ],
                selectedItem: {},
                showDropdown: false
            }
        },
        watch: {
            isDisabled: function (val) {
                if (val) {
                    this.showDropdown = false;
                }
            },
            selectedItem: function (val) {
                this.$emit('updatePrivacy', {
                    id: val.type,
                    field: this.field
                });
            }
        },
        computed: {
			configureListOfOptions() {
				return (this.hasNooneOption) ? this.listOfItems : this.listOfItems.filter((o) => {return o.type !== 0})
			}
        },
        mounted() {
            console.log(this.id);
            if (this.id !== undefined) {
                this.selectedItem = this.listOfItems.filter(o=>{return this.id === o.type})[0];
            } else {
                this.selectedItem = this.listOfItems.filter(o=>{return this.defaultSelected === o.type})[0];
            }
        },
        beforeDestroy () {},
        created() {},
        methods: {
            toggleDropdown (e) {
                e.stopPropagation(); // this will stop propagation of this event to upper level
                if (!this.isDisabled) {
                    this.showDropdown = !this.showDropdown;
                    if (this.showDropdown) {
                        window.addEventListener('click', () => {
                            this.showDropdown = false
                        })
                    } else {
                        window.removeEventListener('click', () => {
                            this.showDropdown = false
                        })
                    }
                }
            },
            selectItem(i) {
                // console.log(i);
                this.selectedItem = i;
                this.showDropdown = false;
            }
        }
    }

</script>

<style lang="less" scoped>
    @import "../../assets/styles/_vars";
    #privacy-dropdown-reg {
        position: relative;
        cursor: pointer;
        border-radius: 3px;
        border: 1px solid #ced4da;
        background-color: white;
        &.disabled {
            background-color: transparent;
            cursor: not-allowed;
            .selected-item {
                .description {
                    color: #9B9B9B;
                }
            }
        }
        .selected-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            flex-direction: row;
            padding: 11px;
            .icon {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-position: center;
                background-repeat: no-repeat;
                margin-right: 6px;
                &.visible-icon {
                    display: inline-flex;
                }
                &.eye {
                    width: 24px;
                    height: 24px;
                    background-image: url(../../assets/img/privacy-all.svg);
                    background-size: 24px;
                }
                &.secret {
                    width: 24px;
                    height: 24px;
                    background-image: url(../../assets/img/privacy-no-one.svg);
                    background-size: 24px;
                }
                &.circle {
                    width: 24px;
                    height: 24px;
                    background-image: url(../../assets/img/radiobutton-checked.svg);
                    background-size: 24px;
                }
            }
            .description {
                font-size: 15px;
                color: #5E6F8F;
            }
            &:after {
                content: url("../../assets/img/chevron-bottom.svg");
                width: 24px;
                height: 24px;
                right: 12px;
                top: 50%;
                position: absolute;
                margin-top: -12px;
            }
        }
        .dropdown-wrapper {
            z-index: 99;
            position: absolute;
            width: 100%;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            border: 1px solid #ced4da;
            background-color: white;
            .item {
                &:hover {
                    background-color: @default_underline;
                }
                padding: 10px 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
                .icon {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-right: 6px;
                    &.visible-icon {
                        display: inline-flex;
                    }
                    &.eye {
                        width: 24px;
                        height: 24px;
                        background-image: url(../../assets/img/privacy-all.svg);
                        background-size: 24px;
                    }
                    &.secret {
                        width: 24px;
                        height: 24px;
                        background-image: url(../../assets/img/privacy-no-one.svg);
                        background-size: 24px;
                    }
                    &.circle {
                        width: 24px;
                        height: 24px;
                        background-image: url(../../assets/img/radiobutton-checked.svg);
                        background-size: 24px;
                    }
                }
                .description {
                    font-size: 15px;
                    color: #5E6F8F;
                }
            }
        }
    }
</style>
