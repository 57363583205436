<template>
	<div id="registration-step-one" :class="{'edit-page': editData}">
		<h1 class="page-title desktop" v-if="!editData">
			<span>Шаг 1 из 2. </span>
			<span>Без этой информации никак не обойтись</span>
		</h1>
		<div class="page-title mobile" v-if="!editData">
			<div class="title">Регистрация</div>
			<div class="visualized-steps">
				<span class="active">1</span>
				<span>2</span>
			</div>
			<div class="descr">Без этой информации никак не обойтись</div>
		</div>
		<div class="privacy-confirmation" v-if="!editData">
			<img src="../../../../assets/img/privacy-no-one.svg" alt="privacy">
			<p>Мы никогда не опубликуем ничего без вашего ведома, и вы всегда сможете скрыть любые данные</p>
		</div>
		<div class="c-wrapper">
			<div class="form-wrapper">
				<ValidationObserver v-slot="{invalid, handleFormSubmit}" ref="form">
					<form class="u-col-12" v-on:submit.prevent="handleFormSubmit(onSubmit($event))">
						<div class="form-group-wrapper">
							<div class="basic-user-data">
								<div class="form-group">
									<ValidationProvider rules="required|min-max:1,30" v-slot="{classes, errors}">
										<label for="name" class="regular-label">
											Имя и пол
										</label>
										<input id="name" type="text" v-model="userData.name" name="name" class="form-control" v-bind:class="classes" placeholder="Можно без фамилии :)"/>
									</ValidationProvider>
								</div>
								<div class="radio-buttons-wrapper">
									<div class="form-group u-radiobutton-wr">
										<ValidationProvider v-slot="{classes, errors}">
											<input id="sex-man" type="radio" @click="uncheckItem(userData.sex, 'sex')" value="m" v-model="userData.sex" name="sex-man"/>
											<label class="label-as-input" for="sex-man" v-bind:class="classes">
												<span class="checkmark"></span>
												Мужчина
											</label>
										</ValidationProvider>
									</div>
									<div class="form-group u-radiobutton-wr">
										<ValidationProvider v-slot="{classes, errors}">
											<input id="sex-woman" type="radio" @click="uncheckItem(userData.sex, 'sex')" value="f" v-model="userData.sex" name="sex-woman"/>
											<label class="label-as-input" for="sex-woman" v-bind:class="classes">
												<span class="checkmark"></span>
												Женщина
											</label>
										</ValidationProvider>
									</div>
								</div>
							</div>
							<!--<div class="privacy-settings">
								<div class="form-group">
									<label for="name-privacy" class="regular-label">
										Кто увидит
									</label>
									<privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="userData.name_visibility" field="name_visibility" :is-disabled="true"></privacy-dropdown-reg>
								</div>
							</div>-->
						</div>
						<div class="form-group-wrapper">
							<div class="basic-user-data">
								<div class="form-group">
									<label for="birthday" class="regular-label">
										Дата рождения (дд.мм.гггг)
									</label>
									<b-input-group>
										<!--@focusout="formatBirthDate(userData.birthday_parsed, 'typed')"-->
										<b-form-input id="birthday" type="text" v-model="userData.birthday_parsed" name="birthday" @change="formatBirthDate(userData.birthday_parsed, 'typed')" class="form-control birthday-input" placeholder="дд.мм.гггг"></b-form-input>
										<span class="clear-birthday-icon" @click="clearBirthday()" v-if="userData.birthday"><i class="far fa-times-circle"></i></span>
										<input id="mobile-date" name="mobile-date" type="date" @change="onMobileSelectChange" v-model="userData.birthday_parsed" required v-if="isMob">
										<b-input-group-append>
											<b-form-datepicker v-model="userData.birthday" button-only right locale="ru-RU" :max="todayDate" :start-weekday="1" :show-decade-nav="true" :hide-header="true" :initial-date="userData.birthday" @input="formatBirthDate($event, 'calendar')" :label-help="''" :required="true"></b-form-datepicker>
										</b-input-group-append>
									</b-input-group>
									<div class="error" v-show="isDateInvalid">Эта дата не может быть использована</div>
									
									<div class="dateofbirdth_option u-checkbox-wr left-side">
										<label for="age_range" class="checkbox-container">
											демонстрировать только возрастной диапазон
											<input type="checkbox" class='custom-checkbox' id="age_range" v-model="userData.age_range" :disabled="!userData.birthday">
											<span class="checkmark"></span>
										
										</label>
										<!--<input type="checkbox" id="age_range" class='u-checkbox' v-model="userData.age_range">
										<label for="age_range">демонстрировать только возрастной диапазон</label>-->
									</div>
								</div>
							</div>
							<div class="privacy-settings">
								<div class="form-group">
									<label for="dateofbirth-privacy" class="regular-label">
										Кто увидит
									</label>
									<privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="userData.birthday_visibility" field="birthday_visibility"></privacy-dropdown-reg>
								</div>
							</div>
						</div>
						<div class="form-group-wrapper">
							<div class="basic-user-data">
								<div class="form-group">
									<label for="user-location" class="regular-label">
										Место жительства
									</label>
									<googleAutocomplete id="user-location" classname="form-control" placeholder="Населенный пункт в любой стране" v-on:placechanged="getAddressData" :savedData="userData.location" types="(cities)" @inputChange="locationInputChange"></googleAutocomplete>
								</div>
							</div>
							<div class="privacy-settings">
								<div class="form-group">
									<label for="location-privacy" class="regular-label">
										Кто увидит
									</label>
									<privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="userData.place_visibility" field="place_visibility"></privacy-dropdown-reg>
								</div>
							</div>
						</div>
						<div class="form-group-wrapper">
							<div class="basic-user-data">
								<div class="form-group">
									<label for="email" class="regular-label">
										E-MAIL
									</label>
									<ValidationProvider rules="required|email" v-slot="{classes, errors}">
										<input id="email" type="text" v-model="userData.email" name="email" class="form-control" :disabled="userData.loginSocial && !isEmailEmpty || editData" :class="classes" placeholder="Будет вашим логином"/>
									</ValidationProvider>
								</div>
							</div>
							<div class="privacy-settings">
								<div class="form-group">
									<label for="email-privacy" class="regular-label">
										Кто увидит
									</label>
									<privacy-dropdown-reg @updatePrivacy="updatePrivacy" :id="userData.email_visibility" field="email_visibility" :has-noone-option="true"></privacy-dropdown-reg>
								</div>
							</div>
						</div>
						<div class="form-group-wrapper avatar-checkboxes">
							<div class="user-avatar-data">
								<div class="form-group">
									<div class="preview">
										<avatar @clickEvent="triggerFileInput()" :width="180" :height="180" :img="image.src ? image.preview: userData.avatar"></avatar>
									</div>
									<div class="upload-btn-wrapper" v-if="!image.src">
										<button @click="triggerFileInput()" type="button" class="def-btn">{{!userData.avatar ? 'Загрузить аватар' : 'Изменить аватар'}}</button>
										<input ref="filesInput" type="file" @change="loadImage" class="custom-file-input-btn" accept="image/png, image/jpeg" name="user-avatar">
									</div>
								</div>
							</div>
							<div class="purpose-of-stay">
								<div class='checkboxes-group-title'>Цель пребывания на сайте</div>
								<div class="checkboxes-wrapper">
									<div class="form-group u-checkbox-wr left-side" v-for="i of registrationData.goals">
										<ValidationProvider v-slot="{classes, errors}">
											<!--<input :class="classes" type="checkbox" class='u-checkbox' v-bind:id="i.name + i.id" v-bind:value="i" v-model="userData.goals">
											<label v-bind:for="i.name + i.id">{{i.name}}</label>-->
											<label :for="i.name+i.id" class="checkbox-container" :class="classes">
												<input type="checkbox" class='custom-checkbox' :value="i" :id="i.name+i.id" v-model="userData.goals">
												<span class="checkmark"></span>
												{{ i.name }}
											</label>
										</ValidationProvider>
									</div>
								</div>
							</div>
						</div>
						<Cropper
								v-if="image.src"
								:imageClass="'cropper-image'"
								:backgroundClass="'cropper-background'"
								class="cropper"
								ref="cropper"
								:src="image.src"
								:stencil-props="{aspectRatio: 10/12}"
								stencil-component="circle-stencil"
								@change="change"
						/>
						<div class="btn-navigate" v-if="image.src">
							<button class="def-btn cancel-action" @click="reset">Отменить</button>
							<button class="def-btn" @click="crop">Сохранить</button>
						</div>
					
					</form>
				</ValidationObserver>
				
				<div class="controls-wrapper">
					<c_button :c_loading="loadingIndicator" :c_class="'submit-btn def-btn'" :c_text="'Продолжить'" :c_loading_reverse="true" @clickEvent="handleFormSubmit"></c_button>
					
					<!--<button class="def-btn submit-btn" @click="handleFormSubmit" v-bind:class="{'loading-spinner-active': loadingIndicator, 'left': editData}" :disabled="loadingIndicator">
						<span>Продолжить</span>
						<img src="../../../../assets/img/Infinity-1s-200px.svg" alt="loading">
					</button>-->
				</div>
			</div>
			<div class="socials-auth-wrapper" v-if="!userData.loginSocial && !editData">
				<p class="socials-title">Чтобы не вводить данные вручную, а так же узнавать новое про своих друзей
					Up&Up:</p>
				<social-buttons @dataHandled="onSocialDataEdit" :isLogin="false"></social-buttons>
				<div class="privacy-confirmation">
					<img src="../../../../assets/img/privacy-no-one.svg" alt="privacy">
					<p>Мы никогда не опубликуем ничего без вашего ведома, и вы всегда сможете скрыть любые данные</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
	let header = '';
	for (let i = 0; i < byteArray.length; i++) {
		header += byteArray[i].toString(16);
	}
	switch (header) {
		case "89504e47":
			return "image/png";
		case "47494638":
			return "image/gif";
		case "ffd8ffe0":
		case "ffd8ffe1":
		case "ffd8ffe2":
		case "ffd8ffe3":
		case "ffd8ffe8":
			return "image/jpeg";
		default:
			return fallback;
	}
}

import googleAutocomplete from "../../../../components/_shared/GoogleAutocomplete";
import privacyDropdownReg from "../../../../components/_shared/PrivacyDropdownReg";
import SocialButtons from "../../../../components/auth/SocialsLogin";
import {authService} from "../../../../_api";
import {toBase64, handleAWSImage} from "../../../../_helpers/aws-handler";
import avatar from "../../../../components/_shared/Avatar";
import detectMobile from "../../../../_helpers/delect-mobile";
import C_button from "@/components/_shared/Button";
import CustomCropper from "../../../../components/_shared/CustomCropper/CustomCropper";
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
	name: 'registration-step-one',
	props: ['registrationData', 'editData'],
	components: {
		C_button,
		'social-buttons': SocialButtons,
		googleAutocomplete,
		privacyDropdownReg,
		avatar,
		CustomCropper,
		Cropper
	},
	data() {
		return {
			show: true,
			photoData: null,
			display: 'none',
			link: 'https://cliparting.com/wp-content/uploads/2018/03/cool-pictures-2018-2.jpg',
			image: {
				src: null,
				type: null,
				preview: null
			},
			preview: null,
			imgDataUrl: '',
			params: {
				token: '123456798',
				name: 'avatar'
			},
			showCropper: false,
			todayDate: this.moment().format('YYYY-MM-DD'),
			loadingIndicator: false,
			isEmailEmpty: true,
			isDateInvalid: false,
			userData: {
				name: '',
				sex: '',
				name_visibility: 1,
				/*birthday: this.moment().subtract(4, 'days').format('YYYY-MM-DD'),
				birthday_parsed: this.moment().subtract(4, 'days').format('YYYY-MM-DD'),*/
				birthday: null,
				birthday_parsed: null,
				birthday_visibility: 1,
				age_range: false,
				location: {},
				place_visibility: 1,
				email: '',
				email_visibility: 1,
				goals: [],
				avatar: '',
				newAvatar: '',
				loginSocial: false
			}
		}
	},
	created() {
		if (!this.editData) {
			let regData = JSON.parse(localStorage.getItem('uReg'));
			if (regData && regData.sOne) {
				this.userData = regData.sOne;
				var objectStore = window.db.transaction("avatar").objectStore("avatar");
				objectStore.openCursor().onsuccess = (event) => {
					var cursor = event.target.result;
					if (cursor) {
						if (cursor.value.avatar) {
							console.log(cursor);
							this.userData.avatar = cursor.value.avatar;
						}
						cursor.continue();
					} else {
						console.log("No more entries!");
					}
				};
			}
			
			if (this.$route.query.invitedBy) {
				localStorage.setItem('invitedBy', this.$route.query.invitedBy)
			}
		} else {
			console.log(this.editData);
			this.userData = {...this.editData};
			this.userData['birthday_parsed'] = this.editData.birthday ? this.moment(this.editData.birthday, 'YYYY-MM-DD').format('DD.MM.YYYY') : null;
			this.userData['name_visibility'] = 1;
			this.userData['age_range'] = this.editData.age_range || false;
			this.userData['orientation_id'] = this.editData.orientation;
			this.userData['family_status_id'] = this.editData.family_status;
			this.userData['children_id'] = this.editData.children;
			this.userData['education_id'] = this.editData.education;
			this.userData['work_status_id'] = this.editData.work_status;
			this.userData['occupations_id'] = this.editData.occupations.filter((obj) => {return obj.id});
			handleAWSImage(this.editData.avatar).then((res) => {
				this.userData.avatar = res;
				/*this.image = {
					src: res
				};*/
			});
		}
	},
	mounted() {
	
	},
	computed: {
		isMob: function () {
			return detectMobile()
		}
	},
	methods: {
		change({coordinates, canvas}) {
			console.log(coordinates, canvas);
			
			this.image.preview = canvas.toDataURL();
			console.log(this.image.preview);
			this.$forceUpdate();
			// this.image.src = canvas.toDataURL();
			// if (this.image.src) {
			// 	console.log(coordinates, canvas);
			// 	console.log(this.newImg);
			// 	console.log(canvas.toDataURL());
			// 	this.userData.avatar = canvas.toDataURL();
			// 	window.db.transaction(['avatar'], 'readwrite').objectStore('avatar').put({
			// 		id: 1,
			// 		avatar: this.userData.avatar,
			// 		avatar_length: this.userData.avatar.length
			// 	});
			// 	// this.image = canvas.toDataURL();
			// }
		},
		crop() {
			const {canvas} = this.$refs.cropper.getResult();
			this.userData.avatar = canvas.toDataURL();
			window.db.transaction(['avatar'], 'readwrite').objectStore('avatar').put({
		        id: 1,
		        avatar: this.userData.avatar,
		        avatar_length: this.userData.avatar.length
			});
			/*canvas.toBlob((blob) => {
				// Do something with blob: upload to a server, download and etc.
			}, this.image.type);*/
			this.reset();
		},
		loadImage() {
			const {files} = event.target;
			if (files && files[0]) {
				if (this.image.src) {
					URL.revokeObjectURL(this.image.src)
				}
				
				const blob = URL.createObjectURL(files[0]);
				const reader = new FileReader();
				reader.onload = (e) => {
					this.image = {
						src: blob,
						type: getMimeType(e.target.result, files[0].type),
						preview: null
					}
					toBase64(files[0]).then(res => {
						console.log(res);
						this.image.preview = res;
						// this.userData.avatar = res;
						// console.log(this.userData.avatar.length);
						// ADDING IMAGE TO INDEXEDDB TO HAVE AN ABILITY TO PASS IN INTO THE SUBMIT METHOD ON SECOND STEP BECAUSE OF UNLIMITED IMAGE SIZE
						window.db.transaction(['avatar'], 'readwrite').objectStore('avatar').put({
							id: 1,
							avatar: res,
							avatar_length: res.length
						});
					});
				}
				
				reader.readAsArrayBuffer(files[0])
			}
			
		},
		reset() {
			this.image = {
				src: null,
				type: null,
				preview: null
			}
			
		},
		
		locationInputChange(res) {
			console.log(res);
			if (res.newVal !== undefined && !res.newVal.length) {
				this.userData.location = {};
			}
		},
		clearBirthday() {
			this.userData.birthday = null;
			this.userData.birthday_parsed = null;
			this.userData.age_range = false;
		},
		uncheckItem(data, field) {
			console.log(data);
			console.log(this.userData[field]);
			if (data === this.userData[field]) {
				console.log('del');
				this.userData[field] = '';
			}
			this.$forceUpdate();
		},
		onMobileSelectChange(e) {
			console.log(e);
			console.log(this.userData.birthday_parsed);
			this.formatBirthDate(this.userData.birthday_parsed, 'calendar');
		},
		updatePrivacy(data) {
			this.userData[data.field] = data.id;
		},
		onSocialDataEdit(val) {
			this.userData = {...this.userData, ...val.data};
			this.isEmailEmpty = val.isEmailEmpty;
		},
		formatBirthDate(data, value) {
			console.log(data);
			console.log(value);
			console.log(' asdf adsfasd');
			if (value === 'typed') {
				this.isDateInvalid = this.moment().format('YYYY-MM-DD') < this.moment(data, 'DD.MM.YYYY').format('YYYY-MM-DD');
				console.log('VALID1');
				console.log(this.moment().format('DD.MM.YYYY'));
				console.log(this.moment(data, 'DD.MM.YYYY').format('DD.MM.YYYY'));
				console.log(this.isDateInvalid);
			} else {
				this.isDateInvalid = this.moment().format('YYYY-MM-DD') < this.moment(data).format('YYYY-MM-DD');
				console.log('VALID2');
			}
			
			if (this.isDateInvalid) {
				this.userData.birthday = null;
				this.userData.birthday_parsed = null;
			} else {
				if (value === 'typed') {
					if (this.moment(data, 'DD.MM.YYYY').isValid()) {
						console.log('IFF');
						this.userData.birthday = this.moment(data, 'DD.MM.YYYY').format('YYYY-MM-DD');
						this.userData.birthday_parsed = this.moment(data, 'DD.MM.YYYY').format('DD.MM.YYYY');
					}/* else {
							console.log('ELSE');
							this.userData.birthday_parsed = this.moment(this.userData.birthday, 'YYYY-MM-DD').format('DD.MM.YYYY');
						}*/
				} else {
					this.userData.birthday = this.moment(data, 'YYYY-MM-DD').format('YYYY-MM-DD');
					this.userData.birthday_parsed = this.moment(data, 'YYYY-MM-DD').format('DD.MM.YYYY');
				}
			}
			
		},
		getAddressData(data) {
			this.userData.location = data;
		},
		handleUserPhoto(e) {
			console.log(e);
			this.photoData = e;
			// this.showCropper = true
			let img = e.target.files[0];
			if (img && (img.type === 'image/jpeg' || img.type === 'image/png')) {
				toBase64(img).then(res => {
					this.userData.avatar = res;
					// console.log(this.userData.avatar.length);
					// ADDING IMAGE TO INDEXEDDB TO HAVE AN ABILITY TO PASS IN INTO THE SUBMIT METHOD ON SECOND STEP BECAUSE OF UNLIMITED IMAGE SIZE
					window.db.transaction(['avatar'], 'readwrite').objectStore('avatar').put({
						id: 1,
						avatar: res,
						avatar_length: res.length
					});
				});
			}
			// this.display = 'block'
		},
		triggerFileInput () {
			console.log(this.$refs);
			this.$refs.filesInput.click()
		},
		handleFormSubmit() {
			if (!this.isDateInvalid) {
				this.$refs.form.validate().then(success => {
					if (!success) {
						return;
					} else {
						if (this.editData) {
							console.log(this.userData);
							this.loadingIndicator = true;
							let objToSend = {
								name: this.userData.name,
								name_visibility: this.userData.name_visibility,
								email_visibility: this.userData.email_visibility,
								sex: this.userData.sex,
								birthday: this.userData.birthday,
								birthday_visibility: this.userData.birthday_visibility,
								age_range: this.userData.age_range,
								place_id: this.userData.location ? this.userData.location.place_id : null,
								place_visibility: this.userData.place_visibility,
								avatar: (this.userData.avatar) ? this.userData.avatar.split(',')[1] : null,
								goals_id: this.userData.goals.map((obj) => {return obj.id})
							};
							console.log(objToSend);
							/*setTimeout(() => {
								this.loadingIndicator = false;
							}, 5000)*/
							this.$store.dispatch('firebase/getUnreadCount', {stopListener: true});
							authService.updateUser(objToSend, null, false).then((res) => {
								console.log(res);
								if (res) {
									this.$emit('userDataUpdated');
									this.$store.dispatch('alert/success', 'Вы успешно изменили данные', {root: true});
								}
								this.$store.dispatch('firebase/getUnreadCount', {stopListener: false});
								this.loadingIndicator = false;
							});
						} else {
							/*let avatar = this.userData.avatar;
							this.userData.avatar = null;
							this.userData['invited_by'] = this.$route.query.invitedBy;
							localStorage.setItem('uReg', JSON.stringify({
								sOne: this.userData
							}));
							this.userData.avatar = avatar;
							this.$router.push('/registration/step-two');*/
							this.userData['invited_by'] = this.$route.query.invitedBy;
							localStorage.setItem('uReg', JSON.stringify({
								sOne: this.userData
							}));
							console.log(this.userData);
							
							this.$router.push('/registration/step-two');
						}
					}
				});
			}
		},
	},
	watch: {}
}
</script>

<style lang="less">
@import "../../../../assets/styles/_vars";

#registration-step-one {
	.cropper {
		height: 450px;
		width: 100%;
		background: #DDD;
	}
	
	&.edit-page {
		.c-wrapper {
			.form-wrapper {
				width: 100% !important;
				
				form {
					padding: 48px 57px 20px 57px;
				}
			}
		}
		
		.upload-btn-wrapper {
			width: 100%;
			text-align: center;
		}
		
		.controls-wrapper {
			padding: 0 10px 10px 10px;
		}
	}
	
	.page-title {
		&.mobile {
			display: none;
			margin-bottom: 16px !important;
			padding: 0 !important;
			
			.title {
				color: #9b9b9b;
				font-size: 18px;
				text-align: center;
				margin-bottom: 3px;
			}
			
			.visualized-steps {
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: nowrap;
				margin-bottom: 20px;
				
				span {
					border-radius: 100%;
					border: 3px solid #dfe3eb;
					width: 34px;
					height: 34px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
					background-color: #cbcbcb;
					color: #e0e3ea !important;
					position: relative;
					
					&.active {
						background-color: #6d819f;
						color: #ffffff !important;
					}
					
					&:first-child {
						margin-right: 20px;
						
						&:after {
							content: '';
							width: 20px;
							height: 3px;
							background-color: #dfe3eb;
							position: absolute;
							top: 50%;
							margin-top: -3px;
							right: -23px;
						}
					}
				}
			}
			
			.descr {
				color: #666666;
				font-size: 15px;
				text-align: center;
			}
		}
	}
	
	.privacy-confirmation {
		display: none;
		background: #F8F8F8;
		border-radius: 5px;
		padding: 10px 15px 10px 15px;
		margin-bottom: 5px;
		
		img {
			width: 23px;
			margin-bottom: 5px;
		}
		
		p {
			color: #71809C;
			font-size: 11px;
			font-weight: 400;
			line-height: 15px;
			text-align: center;
			margin: 0;
		}
	}
	
	.c-wrapper {
		.form-wrapper {
			width: 66% !important;
			
			.form-group-wrapper {
				.basic-user-data {
					width: 66%;
					
					.form-group {
						.input-group {
							position: relative;
							width: 100%;
							
							input {
								// pointer-events: none;
							}
							
							#mobile-date {
								position: absolute;
								top: 0;
								right: 0;
								z-index: 9;
								width: 48px;
								height: 100%;
								opacity: 0;
								-webkit-appearance: none;
								-moz-appearance: none;
							}
							
							.clear-birthday-icon {
								position: absolute;
								font-size: 20px;
								color: gray;
								top: 50%;
								right: 50px;
								z-index: 9;
								margin-top: -14px;
							}
						}
						
						.error {
							color: #dc3545;
							font-size: 13px;
							text-align: left;
						}
						
						.dateofbirdth_option {
							display: flex;
							width: 100%;
							font-size: 12px;
							margin-top: 5px;
							align-items: center;
							
							label {
								margin-bottom: 0;
								text-align: left;
							}
						}
					}
					
					.radio-buttons-wrapper {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						flex-wrap: nowrap;
						
						.form-group {
							margin: 0;
							width: 100%;
							border-radius: 3px;
							
							&:first-child {
								margin-right: 5px;
							}
							
							&:last-child {
								position: relative;
								left: -1px;
							}
							
							span {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								flex-wrap: nowrap;
								
								label {
									padding: 15px 16px 15px 46px;
									width: 100%;
									background-color: white;
									border: 1px solid #E0E3EA;
									
									.checkmark {
										left: 16px;
									}
									
									/*&:before {
										left: 16px;
									}*/
								}
							}
						}
					}
				}
				
				&.avatar-checkboxes {
					flex-wrap: wrap-reverse;
					
					.user-avatar-data {
						width: 50%;
						
						.form-group {
							display: flex;
							justify-content: center;
							align-items: center;
							flex-wrap: wrap;
							
							.preview {
								position: relative;
								margin-bottom: 14px;
								display: flex;
								justify-content: center;
								width: 100%;
								
								&.invalid {
									.image-as-bg {
										border: 1px solid #dc3545;
									}
								}
							}
							
							.upload-btn-wrapper {
								position: relative;
								overflow: hidden;
								display: inline-block;
								.def-btn {
									margin: 0 auto;
									z-index: 5;
								}
								/*button {
									border: none;
									color: #ffffff;
									background-color: @theme_color;
									padding: 9.5px 22px;
									border-radius: 51px;
									font-size: 12px;
									font-weight: 500;
									text-transform: uppercase;
								}*/
								
								input {
									font-size: 100px;
									position: absolute;
									left: 0;
									top: 0;
									opacity: 0;
								}
							}
						}
					}
					
					.purpose-of-stay {
						width: 50%;
						
						.checkboxes-group-title {
							font-size: 11px;
							font-weight: 500;
							text-transform: uppercase;
							color: #666666;
							text-align: left;
							display: flex;
							margin-bottom: 18px;
						}
						
						.u-checkbox-wr {
							&:not(:last-child) {
								margin-bottom: 12px;
							}
							
							.checkbox-container {
								margin: 0;
								font-weight: 400;
								font-size: 15px;
								text-transform: none;
								position: relative;
								padding-left: 30px;
								text-align: left;
								
								.checkmark {
									height: 21px;
									width: 21px;
									margin-top: -10.5px;
									
									&:after {
										left: 6px;
										top: 3px;
									}
								}
							}
							
							/*&>span {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								flex-wrap: nowrap;
							}

							.u-checkbox {
								width: 21px;
							}*/
						}
					}
				}
			}
			
			.controls-wrapper {
				.submit-btn {
					font-size: 16px;
					font-weight: 500;
					padding: 5px 10px;
					min-width: 230px;
					min-height: 50px;
				}
			}
		}
		
		.socials-auth-wrapper {
			width: 27.5%;
			margin-top: 50px;
			
			.socials-title {
				text-align: left;
				color: #666666;
				font-size: 18px;
				margin-bottom: 29px;
				line-height: 21px;
			}
			
			.socials-btns-wrapper {
				margin-bottom: 25px;
				
				button {
					background-repeat: no-repeat;
					width: 100%;
					background-color: @theme_color;
					color: #ffffff;
					border-radius: 51px;
					font-size: 13px;
					font-weight: 500;
					text-transform: uppercase;
					border: none;
					padding: 9.5px 22px;
					background-position: 8% 50%;
					
					&:focus {
						outline: none;
					}
					
					&:not(:last-child) {
						margin-bottom: 12px;
					}
					
					&.fb-btn {
						background-image: url("../../../../assets/img/Icon-social-facebook.svg");
					}
					
					&.vk-btn {
						background-image: url("../../../../assets/img/Icon-social-vk.svg");
					}
				}
				
				p {
					color: #A7A7A7;
					font-size: 18px;
					font-weight: 500;
					margin-bottom: 12px;
				}
			}
			
			.privacy-confirmation {
				background: #F8F8F8;
				border-radius: 5px;
				padding: 31px 24px 21px 24px;
				
				img {
					width: 40px;
					margin-bottom: 16px;
				}
				
				p {
					color: #71809C;
					font-size: 13px;
					font-weight: 400;
					line-height: 18px;
					text-align: left;
				}
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1200px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	#registration-step-one {
		.c-wrapper {
			.form-wrapper {
				form {
					padding: 25px 25px;
					box-shadow: none;
					
					.form-group-wrapper {
						position: relative;
						
						.basic-user-data {
							width: 60%;
							
							.radio-buttons-wrapper {
								.form-group {
									span {
										label {
											padding: 15px 15px 15px 40px;
										}
									}
								}
							}
						}
						
						&.avatar-checkboxes {
							margin-bottom: 0;
						}
					}
				}
				
				.controls-wrapper {
					.submit-btn {
						font-size: 15px;
						padding: 10px 30px;
						width: 100%;
						max-width: 320px;
					}
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 768px) {
	#registration-step-one {
		.privacy-confirmation {
			display: block;
		}
		
		.page-title {
			&.desktop {
				display: none;
			}
			
			&.mobile {
				display: block;
			}
		}
		
		.c-wrapper {
			flex-wrap: wrap;
			
			.form-wrapper, .socials-auth-wrapper {
				width: 100% !important;
			}
			
			.socials-auth-wrapper {
				max-width: 320px;
				margin: 50px auto 0 auto;
			}
			
			.form-wrapper {
				form {
					padding: 20px 12px !important;
					box-shadow: none;
					
					.form-group-wrapper {
						position: relative;
						
						.basic-user-data {
							width: 100% !important;
							
							.form-group {
								margin-bottom: 5px;
								
								.dateofbirdth_option {
									label {
										text-align: left;
									}
								}
								
								#autocomplete-directive {
									.autogeoresult-block {
										font-size: 11px;
									}
								}
							}
						}
						
						.privacy-settings {
							position: absolute;
							right: 0;
							top: -6px;
							width: 130px;
							
							.form-group {
								.regular-label {
									display: none;
								}
								
								#privacy-dropdown-reg {
									.selected-item {
										padding: 0 10px;
										
										.icon {
											background-size: 20px;
											width: 20px;
											height: 20px;
										}
									}
									
									.dropdown-wrapper {
										.item {
											padding: 5px 10px;
											
											.icon {
												background-size: 20px;
												width: 20px;
												height: 20px;
											}
										}
									}
								}
							}
						}
						
						&.avatar-checkboxes {
							margin-bottom: 0;
							
							& > div {
								width: 48%;
								
								&:first-child {
									margin-bottom: 10px;
								}
							}
						}
					}
				}
				
				.controls-wrapper {
					justify-content: center;
					
					.submit-btn {
						font-size: 15px;
						padding: 10px 30px;
						width: 100%;
						max-width: 320px;
					}
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {
	#registration-step-one {
		.c-wrapper {
			.form-wrapper {
				form {
					.form-group-wrapper {
						.basic-user-data {
							.radio-buttons-wrapper {
								.form-group {
									span {
										label {
											padding: 15px 15px 15px 34px !important;
											
											.checkmark {
												left: 12px;
											}
										}
									}
								}
							}
						}
						
						&.avatar-checkboxes {
							& > div {
								width: 100% !important;
							}
						}
					}
				}
			}
		}
	}
}

.img-container {
	height: 140px;
	width: 140px;
}

.cropper {
	display: block;
	height: 140px;
	width: 140px;
	background: #DDD;
}

.vue-avatar-cropper-demo {
	max-width: 18em;
	margin: 0 auto;
}

.avatar {
	width: 160px;
	border-radius: 6px;
	display: block;
	margin: 20px auto;
}

.card-img-overlay {
	display: none;
	transition: all 0.5s;
}

.card-img-overlay button {
	margin-top: 20vh;
}

.card:hover .card-img-overlay {
	display: block;
}

.btnCropper {
	
	cursor: pointer;
	background-color: #4495CE;
	color: #ffffff;
	min-height: 32px;
	min-width: 100px;
	font-size: 10px;
	font-weight: bold;
	text-transform: uppercase;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	right: 0;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border: 1px solid #4495CE;
	border-color: #4495CE;
	border-radius: 35px;
	padding: 5px 15px;
	-webkit-transition: background-color 250ms;
	transition: background-color 250ms;
	position: relative;
}

.btnCropperDanger {
	cursor: pointer;
	background-color: red;
	color: #ffffff;
	min-height: 32px;
	min-width: 100px;
	font-size: 10px;
	font-weight: bold;
	text-transform: uppercase;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border: 1px solid #4495CE;
	border-color: #4495CE;
	border-radius: 35px;
	padding: 5px 15px;
	-webkit-transition: background-color 250ms;
	transition: background-color 250ms;
	position: relative;
	left: -10px;
	
}

.btn-navigate {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	button {
		&:first-child {
			margin-right: 5px;
		}
	}
}
</style>
