<template>
    <div class="socials-btns-wrapper">
        <button class="fb-btn" @click="loginWithFb">Войти через FACEBOOK</button>
        <p>или:</p>
        <button class="vk-btn" @click="logInWithVK">Войти через VKONTAKTE</button>
    </div>
</template>

<script>
    import {authService} from "../../_api";
    import {handleAWSImage} from "../../_helpers/aws-handler";
    import {initFacebookSdk} from "../../_helpers/init-facebook-sdk";

    export default {
        name: 'socialButtons',
        props: ['isLogin'],
        data() {
            return {
                isEmailEmpty: true,
                userData: {},
                s3: null
            }
        },
        components: {},
        computed: {
            getFbData: function() {
                return this.$store.state.authentication.fb_data
            }
        },
        methods: {
            getParamsObj (url) {
                let s = url.split('#');
                if (s.length < 2 || s[1] === '') return s[0];
                let params = s[1];
                params = params.split('&');
                let res = {};
                params.forEach( el => {
                    let val = el.split('=');
                    if (val.length < 2) res[val[0]] = '';
                    else res[val[0]] = val[1]
                });
                return res
            },

            loginWithFb() {
                if (!window.FB) {
                    initFacebookSdk().then(() => {
                        let invitedBy = localStorage.getItem('invitedBy');
                        localStorage.removeItem('invitedBy');
                        authService.loginFb(invitedBy);
                    });
                } else {
                    let invitedBy = localStorage.getItem('invitedBy');
                    localStorage.removeItem('invitedBy');
                    authService.loginFb(invitedBy);
                }
                /*initFacebookSdk().then(() => {
                    let invitedBy = localStorage.getItem('invitedBy');
                    localStorage.removeItem('invitedBy');
                    authService.loginFb(invitedBy);
                });*/
            },
            logInWithVK() {
                const VK_app_id = process.env.VUE_APP_VK_APP_ID;
                const redirect_url = window.location.href;
                window.location.href=`https://oauth.vk.com/authorize?client_id=${VK_app_id}&redirect_uri=${redirect_url}&display=popup&scope=friends,notify,photos,wall,email,mail,groups,stats,offline&response_type=token&v=5.74`;
            },
            logInWithVkOnload() {
                let params = this.getParamsObj(window.location.href);
                if (params && params.access_token) {
                    params['invitedBy'] = localStorage.getItem('invitedBy');
                    localStorage.removeItem('invitedBy');
                    authService.loginVk(params).then(() => {
                        // DELETING VK GENERATED QUERY PARAMS
                        if (!this.isLogin) {
                            let queries = JSON.parse(JSON.stringify(this.$route.query));
                            queries.origin = this.userOrigin;
                            queries.destination = this.userDestination;
                            this.$router.replace({ query: queries });
                        }
                    })
                }
            },

            dataAutocompleteHandler(val) {
                handleAWSImage(val.avatar).then((res) => {
                    this.isEmailEmpty = !val.email;
                    this.userData['name'] = val.name;
                    this.userData['email'] = val.email;
                    this.userData['avatar'] = res;
                    this.userData['id'] = val.id;
                    this.userData['api_key'] = val.api_key;
                    this.userData['loginSocial'] = true;
                    this.onDataChange(this.userData, this.isEmailEmpty);
                })

            },

            onDataChange(data, isEmailEmpty) {
                // console.log(isEmailEmpty);
                let obj = {
                    data: data,
                    isEmailEmpty: isEmailEmpty
                };
                this.$emit('dataHandled', obj);
            }
        },
        created () {
            this.logInWithVkOnload();

            if (localStorage.getItem('socialRegUser')) {
                let val = JSON.parse(localStorage.getItem('socialRegUser'));
                this.dataAutocompleteHandler(val);
            }
        },
        watch: {
            getFbData: function(val) {
                console.log(val);
                this.$store.dispatch('authentication/login', val);
                /*if (!val.is_first_login) {
                    this.$store.dispatch('authentication/login', val);
                } else {
                    if (this.isLogin) {
                        this.$router.push('/registration/step-one');
                    } else {
                        this.dataAutocompleteHandler(val);
                    }
                }*/


                /*if (this.isLogin) {
                    if (val.birthday !== null && val.location && val.location.place_id !== null) {
                        this.$store.dispatch('authentication/login', val);
                    } else {
                        this.$router.push('/registration/step-one');
                    }
                } else {
                    if (val.birthday !== null && val.location && val.location.place_id !== null) {
                        this.$store.dispatch('authentication/login', val);
                    } else {
                        this.dataAutocompleteHandler(val);
                    }
                }*/
                //this.dataAutocompleteHandler(val);
            }
        }
    }
</script>

<style lang="less">
    @import "../../assets/styles/_vars";
    .socials-btns-wrapper {
        margin-bottom: 25px;
        button {
            background-repeat: no-repeat;
            width: 100%;
            background-color: @theme_color;
            color: #ffffff;
            border-radius: 51px;
            font-size: 13px;
            font-weight: 500;
            text-transform: uppercase;
            border: none;
            padding: 9.5px 22px;
            background-position: 8% 50%;
            &:focus {
                outline: none;
            }
            &:not(:last-child) {
                margin-bottom: 12px;
            }
            &.fb-btn {
                background-image: url("../../assets/img/Icon-social-facebook.svg");
            }
            &.vk-btn {
                background-image: url("../../assets/img/Icon-social-vk.svg");
            }
        }
        p {
            color: #A7A7A7!important;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 12px;
        }
    }
</style>
